import axios from "axios";
import {
    DELETE_FORM_PARAM_ACTION,
    FETCH_FORM_CONFIG_ACTION,
    FETCH_FORM_PARAMS_ACTION, FETCH_FORM_RESULT_ACTION,
    FETCH_ITEM_FORM_DATA_ACTION,
    SEND_FORM_PARAMS_ACTION,
    SET_FORM_CONFIG_MUTATION,
    SET_FORM_PARAMS_MUTATION, SET_FORM_RESULTS_MUTATION, FETCH_PRODUCTS_HISTORY_ACTION
} from "@/store/storeconstants";

export default {
    async [FETCH_FORM_PARAMS_ACTION](context, payload){
        console.log(payload.data)
        return await axios.get('forms/'+ payload.form + '/results',{
            params: payload.data
        }).then(res => {
            context.commit(SET_FORM_PARAMS_MUTATION,res.data);
            return res.data;
        }).catch(err => {
            console.log(err)
            return null;
        })
    },

    async [FETCH_FORM_RESULT_ACTION](context, payload){
        return await axios.get('forms/'+ payload.form).then(res => {
            context.commit(SET_FORM_RESULTS_MUTATION,res.data);
            return res.data;
        }).catch(err => {
            console.log(err)
            return null;
        })
    },

    async [DELETE_FORM_PARAM_ACTION](context, payload){
        return await axios.delete('forms/'+ payload.form + '/delete/' + payload.id).then(res => {
            return res;
        }).catch(err => {
            console.log(err);
            return null;
        })
    },

    async [FETCH_FORM_CONFIG_ACTION](context, payload){
        return await axios.get('forms/' + payload.form + '/config').then(res => {
            context.commit(SET_FORM_CONFIG_MUTATION, res.data);
            return res.data;
        }).catch(err => {
            console.log(err);
            return null;
        })
    },

    async [SEND_FORM_PARAMS_ACTION](context, payload){
        return await axios.post('forms/' + payload.form + '/submit',payload.data).then(res => {
            console.log(res)
        }).catch(err => {
            console.log(err);
        })

    },

    async [FETCH_ITEM_FORM_DATA_ACTION](context, payload){
        return await axios.get('forms/' + payload.form + '/item/' + payload.id).then(res => {
            console.log(res)
            return res.data;
        }).catch(err => {
            console.log(err)
            return null;
        })
    },

    async [FETCH_PRODUCTS_HISTORY_ACTION] (context, payload){
        return await axios.post(`history`, payload).then(response => {
            return response;
        }).catch(error => {
            console.log(error);
            return null;
        })
    }
};
